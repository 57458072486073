<template>
  <div id="newsList">
    <LargeContainer>
      <div class="searchModalWrapper">
        <form @submit.prevent="searchRequest()">
          <InputSearch
            @iconClick="searchRequest()"
            @change="changeInput"
            :searchText="searchText"
          />
          <!-- <b-field>
            <b-input
              v-model="searchText"
              placeholder="Search..."
              type="search"
              icon="search"
              icon-clickable
              @icon-click="searchRequest()"
            >
            </b-input>
          </b-field> -->
        </form>
      </div>
      <div v-if="posts.data.length === 0" class="errorBox">
        データはありません
      </div>
      <newsListBlock :newsItems="posts.data" />
      <div class="buttonWrapper">
        <Button class="btnPager" @click="prevPage()">Prev</Button>
        <Button class="btnPager" @click="nextPage()">Next</Button>
      </div>
    </LargeContainer>
  </div>
</template>

<script>
import axios from "axios";
import config from "../constants/config";
import { mapActions } from "vuex";
import newsListBlock from "@/components/organisms/newsListBlock.vue";
const endpoint = config.ENDPOINT;
export default {
  name: "newsList",
  components: {
    newsListBlock
  },
  data() {
    return {
      searchText: "",
      currentPage: 1,
      errorLoading: false,
      posts: {
        data: [],
        isLoaded: false
      }
    };
  },
  created() {
    this.set();
  },
  methods: {
    ...mapActions({
      showLoading: "Utility/showLoading",
      hideLoading: "Utility/hideLoading"
    }),
    set() {
      // this.requestNews();
    },
    searchRequest() {
      if (this.searchText === "") {
        alert("入力してください");
        return;
      }
      this.requestNews();
    },
    async requestNews() {
      this.showLoading();
      const url = `${endpoint}search.php`;
      try {
        const result = await axios.get(url, {
          params: {
            search: this.searchText,
            page: this.currentPage
          }
        });
        if (result.data.error !== undefined) {
          this.errorLoading = true;
          //データの削除
          this.posts.data = [];
          return;
        }
        this.errorLoading = false;
        //テスト用の配列を複製して数を増やす
        // for (let i = 0; i < 4; i++) {
        //   result.data.forEach(item => {
        //     this.posts.data.push(item);
        //   });
        // }
        //本番用
        this.posts.data = result.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.hideLoading();
      }
    },
    changeInput(val) {
      this.searchText = val;
    },
    nextPage() {
      if (this.posts.data.length === 0) return;
      this.currentPage++;
      this.requestNews();
    },
    prevPage() {
      if (this.currentPage === 1) return;
      this.currentPage--;
      this.requestNews();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
#newsList {
  .searchModalWrapper {
    margin: setSingleMargin(1);
  }
  .errorBox {
    margin: setSingleMargin(2);
    text-align: center;
  }
  .buttonWrapper {
    .btnPager {
      margin: setSingleMargin(1);
    }
  }
}
</style>
